<template>
   <div class="umrah-container">
      <!-- <navbar /> -->
      <div class="container">
         <b-card no-body>
            <b-tabs pills card v-model="tabIndex">
               <b-row>
                  <div class="col-md-4" style="background: #212121; color: #fff">
                     <b-tab title="Economy Package" active>
                        <b-card-text>Add some details to your package...</b-card-text>
                        <div class="form-container">
                           <div class="row">
                              <div class="col">
                                 <b-form @submit.prevent="submit" class="form mb-3">
                                    <b-form-group
                                    id="email-label"
                                    label="Email address:"
                                    label-for="email"
                                    description="We'll never share your email with anyone else."
                                    class="mb-3"
                                    >
                                    <b-form-input
                                       id="email"
                                       v-model="pckg.email"
                                       type="email"
                                       placeholder="Enter your email address"
                                       required
                                    ></b-form-input>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group
                                    id="name-label"
                                    label="Name:"
                                    label-for="name"
                                    class="mb-3"
                                    >
                                    <b-form-input
                                       id="name"
                                       v-model="pckg.name"
                                       type="text"
                                       placeholder="Enter Full Name"
                                       required
                                    ></b-form-input>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <div class="row" style="padding: 0; margin: 0">
                                       <div class="col-md-3" style="padding: 0; margin: 0 10px 0 0">
                                          <b-form-group
                                          id="name-label"
                                          label="Code:"
                                          label-for="code"
                                          class="mb-3"
                                          >
                                          <b-form-input
                                             id="code"
                                             v-model="pckg.code"
                                             type="number"
                                             placeholder="971"
                                             required
                                          ></b-form-input>
                                          </b-form-group>
                                       </div>   
                                       <div class="col-md" style="padding: 0; margin: 0">
                                          <b-form-group
                                          id="name-label"
                                          label="Mobile Number:"
                                          label-for="number"
                                          class="mb-3"
                                          >
                                          <b-form-input
                                             id="number"
                                             v-model="pckg.number"
                                             type="number"
                                             placeholder="Enter Mobile Number"
                                             required
                                             
                                          ></b-form-input>
                                          </b-form-group>
                                       </div>   
                                    </div>                              

                                    <b-form-group id="adult-label" label="No: of Adults" label-for="adults">
                                    <b-form-select
                                       id="adults"
                                       v-model="pckg.adults"
                                       :options="optionsAdults"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group id="adult-label" label="No: of Children" label-for="children">
                                    <b-form-select
                                       id="children"
                                       v-model="pckg.children"
                                       :options="optionschild"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group id="adult-label" label="No: of Infants" label-for="infant">
                                    <b-form-select
                                       id="infant"
                                       v-model="pckg.infants"
                                       :options="optionsinfant"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- <hr/> -->
<!-- 
                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>Subtotal</label>
                                          <span>AED {{ pckg.subtotal }}</span>
                                       </b-col>
                                    </b-row>

                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>VAT [ 5% ]</label>
                                          <span>AED {{ pckg.vat }}</span>
                                       </b-col>
                                    </b-row>

                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>Grand Total</label>
                                          <span>AED {{ pckg.grandtotal }}</span>
                                       </b-col>
                                    </b-row> -->
                                    
                                    <hr/>

                                    <b-button class="submit" type="submit">Submit Request</b-button>
                                    <div class="error mt-3" v-if="error.length != 0">{{ error }}</div>
                                    
                                 </b-form>
                              </div>
                           </div>
                        </div>
                     </b-tab>
                     <b-tab title="Deluxe Package">
                        <b-card-text>Add some details to your package...</b-card-text>
                        <div class="form-container">
                           <div class="row">
                              <div class="col">
                                 <b-form @submit.prevent="submit" class="form mb-3">
                                    <b-form-group
                                    id="email-label"
                                    label="Email address:"
                                    label-for="email"
                                    description="We'll never share your email with anyone else."
                                    class="mb-3"
                                    >
                                    <b-form-input
                                       id="email"
                                       v-model="pckg.email"
                                       type="email"
                                       placeholder="Enter your email address"
                                       required
                                    ></b-form-input>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group
                                    id="name-label"
                                    label="Name:"
                                    label-for="name"
                                    class="mb-3"
                                    >
                                    <b-form-input
                                       id="name"
                                       v-model="pckg.name"
                                       type="text"
                                       placeholder="Enter Full Name"
                                       required
                                    ></b-form-input>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <div class="row" style="padding: 0; margin: 0">
                                       <div class="col-md-3" style="padding: 0; margin: 0 10px 0 0">
                                          <b-form-group
                                          id="name-label"
                                          label="Code:"
                                          label-for="code"
                                          class="mb-3"
                                          >
                                          <b-form-input
                                             id="code"
                                             v-model="pckg.code"
                                             type="number"
                                             placeholder="971"
                                             min="1"
                                             max="5"
                                             required
                                          ></b-form-input>
                                          </b-form-group>
                                       </div>   
                                       <div class="col-md" style="padding: 0; margin: 0">
                                          <b-form-group
                                          id="name-label"
                                          label="Mobile Number:"
                                          label-for="number"
                                          class="mb-3"
                                          >
                                          <b-form-input
                                             id="number"
                                             v-model="pckg.number"
                                             type="number"
                                             placeholder="Enter Mobile Number"
                                             required
                                             
                                          ></b-form-input>
                                          </b-form-group>
                                       </div>   
                                    </div>                              

                                    <b-form-group id="adult-label" label="No: of Adults" label-for="adults">
                                    <b-form-select
                                       id="adults"
                                       v-model="pckg.adults"
                                       :options="optionsAdults"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group id="adult-label" label="No: of Children" label-for="children">
                                    <b-form-select
                                       id="children"
                                       v-model="pckg.children"
                                       :options="optionschild"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group id="adult-label" label="No: of Infants" label-for="infant">
                                    <b-form-select
                                       id="infant"
                                       v-model="pckg.infants"
                                       :options="optionsinfant"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- <hr/> -->
<!-- 
                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>Subtotal</label>
                                          <span>AED {{ pckg.subtotal }}</span>
                                       </b-col>
                                    </b-row>

                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>VAT [ 5% ]</label>
                                          <span>AED {{ pckg.vat }}</span>
                                       </b-col>
                                    </b-row>

                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>Grand Total</label>
                                          <span>AED {{ pckg.grandtotal }}</span>
                                       </b-col>
                                    </b-row> -->
                                    
                                    <hr/>

                                    <b-button class="submit" type="submit">Submit Request</b-button>
                                    <div class="error mt-3" v-if="error.length != 0">{{ error }}</div>
                                    
                                 </b-form>
                              </div>
                           </div>
                        </div>
                     </b-tab>
                     <b-tab title="Premium Package">
                        <b-card-text>Add some details to your package...</b-card-text>
                        <div class="form-container">
                           <div class="row">
                              <div class="col">
                                 <b-form @submit.prevent="submit" class="form mb-3">
                                    <b-form-group
                                    id="email-label"
                                    label="Email address:"
                                    label-for="email"
                                    description="We'll never share your email with anyone else."
                                    class="mb-3"
                                    >
                                    <b-form-input
                                       id="email"
                                       v-model="pckg.email"
                                       type="email"
                                       placeholder="Enter your email address"
                                       required
                                    ></b-form-input>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group
                                    id="name-label"
                                    label="Name:"
                                    label-for="name"
                                    class="mb-3"
                                    >
                                    <b-form-input
                                       id="name"
                                       v-model="pckg.name"
                                       type="text"
                                       placeholder="Enter Full Name"
                                       required
                                    ></b-form-input>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <div class="row" style="padding: 0; margin: 0">
                                       <div class="col-md-3" style="padding: 0; margin: 0 10px 0 0">
                                          <b-form-group
                                          id="name-label"
                                          label="Code:"
                                          label-for="code"
                                          class="mb-3"
                                          >
                                          <b-form-input
                                             id="code"
                                             v-model="pckg.code"
                                             type="number"
                                             placeholder="971"
                                             min="1"
                                             max="5"
                                             required
                                          ></b-form-input>
                                          </b-form-group>
                                       </div>   
                                       <div class="col-md" style="padding: 0; margin: 0">
                                          <b-form-group
                                          id="name-label"
                                          label="Mobile Number:"
                                          label-for="number"
                                          class="mb-3"
                                          >
                                          <b-form-input
                                             id="number"
                                             v-model="pckg.number"
                                             type="number"
                                             placeholder="Enter Mobile Number"
                                             required
                                             
                                          ></b-form-input>
                                          </b-form-group>
                                       </div>   
                                    </div>                              

                                    <b-form-group id="adult-label" label="No: of Adults" label-for="adults">
                                    <b-form-select
                                       id="adults"
                                       v-model="pckg.adults"
                                       :options="optionsAdults"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group id="adult-label" label="No: of Children" label-for="children">
                                    <b-form-select
                                       id="children"
                                       v-model="pckg.children"
                                       :options="optionschild"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- ----------------------------------------------------------- -->

                                    <b-form-group id="adult-label" label="No: of Infants" label-for="infant">
                                    <b-form-select
                                       id="infant"
                                       v-model="pckg.infants"
                                       :options="optionsinfant"
                                       class="w-50 select mb-2"
                                       required
                                    ></b-form-select>
                                    </b-form-group>

                                    <!-- <hr/> -->
<!-- 
                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>Subtotal</label>
                                          <span>AED {{ pckg.subtotal }}</span>
                                       </b-col>
                                    </b-row>

                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>VAT [ 5% ]</label>
                                          <span>AED {{ pckg.vat }}</span>
                                       </b-col>
                                    </b-row>

                                    <b-row class="d-flex flex-row flex-nowrap mb-4">
                                       <b-col sm="8" class="d-flex justify-content-between">
                                          <label>Grand Total</label>
                                          <span>AED {{ pckg.grandtotal }}</span>
                                       </b-col>
                                    </b-row> -->
                                    
                                    <hr/>

                                    <b-button class="submit" type="submit">Submit Request</b-button>
                                    <div class="error mt-3" v-if="error.length != 0">{{ error }}</div>
                                    
                                 </b-form>
                              </div>
                           </div>
                        </div>
                     </b-tab>
                  </div>
                  <div class="col-md-8" v-if="tabIndex === 0">
                     <div class="row" style="padding: 20px 0">
                        <b-table striped hover bordered :items="tableItems[0]" style="text-align: center"></b-table>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <h4>Package Includes: </h4>
                           <ol class="include-content">
                              <li>
                                 <span>Umrah visa with processing fee, Air ticket departure from Dubai to Jeddah and Madinah to Dubai by Saudi Airlines</span>
                              </li>
                              <li>
                                 <span>Airport transfer Super Luxury Buses Jeddah to Makkah
                                    03 Nights Stay at Makkah at fajrbadee Hotel or similar (3*/450 meter to Haram)
                                 </span>
                              </li>
                              <li>
                                 <span>Transfer by Makkah to Madinah Super Luxury Bus</span>
                              </li>
                              <li>
                                 <span>02 Nights Stay in Madinah Lulu Mubarak hotel or similar (3*/250 meter to Haram)</span>
                              </li>
                              <li>
                                 <span>Makkah and Madina Holy Places Visits Jabel, Noor,Mina,Muzdalifa,Araffah,MasjidQubba,Uhud Mountain,Jannath al Muhallah,Jannath al Bakeeh</span>
                              </li>
                              <li>
                                 <span>Transfer From Madina to madina airport Super Luxury Bus</span>
                              </li>
                              <li>
                                 <span>Umra Class & Multi lingual guide will accompanied the group</span>
                              </li>
                           </ol>
                           <span class="text-danger">All Prices are in UAE Dirhams/person in Sharing </span>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-8" v-if="tabIndex === 1">
                     <div class="row" style="padding: 20px 0">
                        <b-table striped hover bordered :items="tableItems[1]" style="text-align: center"></b-table>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <h4>Package Includes: </h4>
                           <ol class="include-content">
                              <li>
                                 <span>Umrah visa with processing fee</span>
                              </li>
                              <li>
                                 <span>Air ticket departure from Dubai to Jeddah and Madinah to Dubai by Saudi Airlines
                                 </span>
                              </li>
                              <li>
                                 <span>Airport transfer Super Luxury Buses Jeddah to Makkah</span>
                              </li>
                              <li>
                                 <span>03 Nights Stay with Break Fast in Makkah ' Elaf Mashaer Hotel' or similar (5*/350 meter to Haram)</span>
                              </li>
                              <li>
                                 <span>Transfer by Makkah to Madinah Super Luxury Bus</span>
                              </li>
                              <li>
                                 <span>02 Nights Stay with Break Fast in Madinah 'Millenium Thayyiba' Hotel or similar (5*/100 meter to Haram) </span>
                              </li>
                              <li>
                                 <span>Makkah and Madina Holy Places Visits Jabel Noor,Mina,Muzdalifa,Araffah,MasjidQubba,Uhud Mountain,Jannath al Muhallah,Jannath al Bakeeh </span>
                              </li>
                              <li>
                                 <span>Transfer From Madina to Madina Airport Super Luxury Bus</span>
                              </li>
                              <li>
                                 <span>Umra Class & Multi lingual guide will accompanied the group</span>
                              </li>
                           </ol>
                           <span class="text-danger">All Prices are in UAE Dirhams/person in Sharing </span>
                        </div>
                     </div>
                  </div>
                  <div class="col-md-8" v-if="tabIndex === 2">
                     <div class="row" style="padding: 20px 0">
                        <b-table striped hover bordered :items="tableItems[2]" style="text-align: center"></b-table>
                     </div>
                     <div class="row">
                        <div class="col-md-12">
                           <h4>Package Includes: </h4>
                           <ol class="include-content">
                              <li>
                                 <span>Umrah visa with processing fee</span>
                              </li>
                              <li>
                                 <span>Air ticket departure from Dubai to Jeddah and Madinah to Dubai by Saudi Airlines
                                 </span>
                              </li>
                              <li>
                                 <span>Airport transfer Super Luxury Buses Jeddah to Makkah</span>
                              </li>
                              <li>
                                 <span>03 Nights Stay with Break Fast in Makkah Clock Tower Hotel(5*/facing to Haram)</span>
                              </li>
                              <li>
                                 <span>Transfer by Makkah to Madinah Super Luxury Bus</span>
                              </li>
                              <li>
                                 <span>02 Nights Stay with Break Fast in Madinah Hilton/Movenpick Hotel (5*/facing to haram)</span>
                              </li>
                              <li>
                                 <span>Makkah and Madina Holy Places Visits Jabel Noor,Mina,Muzdalifa,Araffah,MasjidQubba,Uhud Mountain,Jannath al Muhallah,Jannath al Bakeeh</span>
                              </li>
                              <li>
                                 <span>Transfer From Madina to Madina Airport Super Luxury Bus</span>
                              </li>
                              <li>
                                 <span>Umra Class & Multi lingual guide will accompanied the group</span>
                              </li>
                           </ol>
                           <span class="text-danger">All Prices are in UAE Dirhams/person in Sharing </span>
                        </div>
                     </div>
                  </div>
               </b-row>
            </b-tabs>
         </b-card>
      </div>
   </div>
</template>
<script>
// import Navbar from '../../components/Navbar.vue'
import {isLoggedIn} from '../../helpers/helper'
import axios from 'axios'

export default {
   // components: { Navbar },
   data() {
      return {
         error: '',
         pckg: {
            type: String,
            adults: Number,
            children: Number,
            infants: Number,
            // subtotal: 0,
            // grandtotal: 0,
            email: null,
            // vat: 0,
            // agentid: '',
            user: {},
            name: '',
            code: '',
            number: ''
         },
         phonenumber: Number,
         options: {
            adults: 0
         },
         tabIndex: 0,
         tableItems: {
            0: [
               {'Quad [4]': 3250, "Triple [3]": 3550, 'Double [2]': 3750, 'Single [1]': 4250, ChildWithoutBed: 2850, Infant: 1100}
            ],
            1: [
               {'Quad [4]': 3750, "Triple [3]": 3950, 'Double [2]': 4250, 'Single [1]': 5250, ChildWithoutBed: 2850, Infant: 1100}
            ],
            2: [
               {'Quad [4]': 4850, "Triple [3]": 4990, 'Double [2]': 5500, 'Single [1]': 7500, ChildWithoutBed: 2850, Infant: 1100}
            ]
         }
      }
   },
   computed: {
      optionsAdults: () => {
         let count = []
         for(let i = 0; i <= 30; i++) {
            count.push(i)
         }
         return count
      },
      optionschild: () => {
         let count = []
         for(let i = 0; i <= 30; i++) {
            count.push(i)
         }
         return count
      },
      optionsinfant: () => {
         let count = []
         for(let i = 0; i <= 10; i++) {
            count.push(i)
         }
         return count
      },

   },
   mounted() {
      if(isLoggedIn() === true) {
         let user = this.$store.state.login.login.user
         this.pckg.email = user.AgtEmail
      }
   },
   methods: {
      async submit() {
         let vm = this

         if(this.tabIndex === 0) {
            this.pckg.type = 'Economy Package'
         } else if(this.tabIndex === 1) {
            this.pckg.type = 'Deluxe Package'
         } else if(this.tabIndex === 2) {
            this.pckg.type = 'Premium Package'
         }

         if(!_.isEmpty(this.pckg.code) && !_.isEmpty(this.pckg.number)) {
            this.phonenumber = this.pckg.code + this.pckg.number
         } else {
            return this.error = 'Phone Number is not valid'
         }

         await axios.post('https://www.desertlinktours.com/api/umrahpackage', {
            name: vm.pckg.name,
            type: vm.pckg.type,
            adults: vm.pckg.adults,
            children: vm.pckg.children,
            infants: vm.pckg.infants,
            email: vm.pckg.email,
            name: vm.pckg.name,
            number: vm.phonenumber
         }).then((res) => {
            if(res.status === 200) {
               vm.error = "REQUEST SUBMITTED SUCCESSFULLY"
            } else {
               vm.error = "There was a processing error! Try Again Later!"
            }
            setTimeout(() => {
               vm.resetForm()
            }, 3000)

         }).catch((err) => console.debug(err))
      },
      resetForm() {
         this.pckg = {
            name: '',
            type: '',
            adults: '',
            children: '',
            infants: '',
            email: '',
            name: '',
            number: ''
         }
         this.phonenumber = ''
         this.error = ''
      }
   }
}
</script>
<style lang="scss" scoped>

.umrah-container {
   height: 100vh;
   background: url('../../assets/backgroundColor.png');

   .container {
      padding: 20px 0;
      background: transparent;

      .form {
         padding: 20px;
         box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
      }

      .error {
         border: 1px solid white;
         padding: 10px;
         color: white;
      }

      .row {
         margin: 0;
      }

      .submit {
         width: 100%;
         text-align: center;
         background: #fff;
         color: #212121;
         font-weight: 300;
         text-transform: uppercase;
         letter-spacing: .1em;
         transition: all 500ms ease-in-out;
         border: 1px solid #212121;

         &:hover {
            background: #212121;
            color: #fff;
            border: 1px solid #fff;
         }
      }


      .select {
         height: 30px;
         border: 1px solid #dddddd;
         text-align: center;
         border-radius: 4px;
      }

      .form-container {
         display: flex;
         padding: 10px 0;
         // justify-content: center;

      }

      .include-content {
         font-family: 'Open Sans', sans-serif;
         font-size: 14px;
         font-weight: 400;
         line-height: 1.6rem;
         list-style-type: square;

         h4 {
            font-weight: 500;
            font-family: 'Open Sans', sans-serif;
         }
      }
   }
}

</style>